import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Badge, Breadcrumb } from 'react-bootstrap';
import ImgPerson from "../../../images/person.jpg"
import Breadcrumbs_Insights from "../../Breadcrumbs/Breadcrumbs_Insights"

// Style
import './JobDetail.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import MemberCard from "../../MemberCard/MemberCard"
import { useStaticQuery, graphql } from "gatsby"

const JobDetail = (props) => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          JobDetails_Right_Side_Block {
            id
            Title
            Choose_Peoples {
              Name
              Email
              Image:Tile_Image {
                url
                alternativeText
              }
              Phone
              Office_Phone:Phone
              Whatsapp_No:WhatsApp
            }
            Content
            CTA_1_Label
            CTA_2_Label
            CTA_1_URL
            CTA_2_URL
          }
        }
      }
    }

  `)

  var job_side_block = data.glstrapi.globalModule.JobDetails_Right_Side_Block;

  //console.log("job_side_block", job_side_block)

  return (
    <div className="Job-detail-block">

        {/* <div className="breadcrumb-block breadcrumb-content text-center" >
          <Container>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">About</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Careers</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Sales Negotiatior</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Container>
        </div> */}
        {/* ======= Breadcrumbs ====== */}

        <Container>
        <Breadcrumbs_Insights page_name={"Careers"} Heading={props.role_data && props.role_data.Title} location={props.location}/>
        <Row>
          <Col xl="7">
             <div className="animated">
              {
                props.role_data &&
                <div className="benefit-block-content">
                  <h1>{props.role_data.Title}</h1>
                  <ul className="project-info">
                    {props.role_data.Address &&
                      <li>
                        <strong>Location :</strong>
                        <span>{props.role_data.Address}</span>
                      </li>
                    }
                    {props.role_data.Annual_Income &&
                      <li>
                        <strong>Annual Income :</strong>
                        <span>{props.role_data.Annual_Income}</span>
                      </li>
                    }
                    {props.role_data.Hours &&
                      <li>
                        <strong>Hours :</strong>
                        <span>{props.role_data.Hours}</span>
                      </li>
                    }
                  </ul>
                  <a href="javascript:;" className="btn btn-primary" onClick={props.openModalForm}>
                    <span>Apply for this Job</span>
                  </a>
                </div>
              }
             </div>
          </Col>
        </Row>

        <Row>
          <Col xl="7">
             <div className="animated">
              {
                props.role_data &&
                <div className="Job-detail-content">
                  {
                    props.role_data.Content && 
                    <div className="Job-detail-text">
                      {HTMLReactParser(props.role_data.Content)}
                    </div>
                  }
                  {props.role_data.Your_Role &&
                    <>
                      <h3>Your Role :</h3>
                      {props.role_data.Your_Role && HTMLReactParser(props.role_data.Your_Role)}
                    </>
                  }
                  {props.role_data.Required_Attributes &&
                    <>
                      <h3>Required Attributes :</h3>
                      {props.role_data.Required_Attributes && HTMLReactParser(props.role_data.Required_Attributes)}
                    </>
                  }
                  {props.role_data.About_you &&
                    <>
                      <h3>About you :</h3>
                      {props.role_data.About_you && HTMLReactParser(props.role_data.About_you)}
                    </>
                  }

                  {/* <p className="highlight-text">Quisque sed fermentum metus, vitae condimentum turpis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec id nisl lorem. In ac felis venenatis sem feugiat ultrices at a arcu. Etiam in diam elementum, laoreet ante in, sollicitudin magna.</p> */}
                </div>
              }
             </div>
          </Col>

          <Col xl="4 ml-xl-auto">
             <div className="animated">
              <div class="member-card-block">
                <MemberCard
                  title={job_side_block.Title}
                  content={job_side_block.Content}
                  btnText={job_side_block.CTA_1_Label}
                  btnTextSecondary={job_side_block.CTA_2_Label}
                  btnLink={job_side_block.CTA_1_URL}
                  btnLinkSecondary={job_side_block.CTA_2_URL}
                  Office={job_side_block.Choose_Office}
                  openModalForm={props.openModalForm}
                />
              </div>
             </div>
          </Col>

        </Row>
        </Container>



    </div>
  )
}

export default JobDetail