import React, {useState} from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import SEO from "../components/seo";
import JobDetail from "../components/StaticTwo/JobDetail/JobDetail";
import { JobsDetails } from "../queries/common_use_query"
import Modal from "react-bootstrap/Modal"
import CareerForm from "../components/forms/career-form"
import LoaderLogo from '../images/loader_logo_new.svg';
// styles

// markup
const CareersDetailsTemplate = ( props ) => {

  const [isOpen,setIsOpen] = useState(false);
  const openModalForm = () => {
    setIsOpen(true);
  }

  let path_name = (props.location.pathname).split('/');
  let get_url = path_name[path_name.length-1] !== "" ? path_name[path_name.length-1] : path_name[path_name.length-2];

  const {loading, error, data} = JobsDetails(get_url);

  // console.log ('&&&&&', data)

  return (
    <Layout Layout={'Without_Banner'} classNames="Without_Banner">

      {
        (loading) ? (
          // <div className="main-banner no_lines">
          //     <div className="loader_div">
          //         {/* <img src={LoaderLogo} alt="Loader" title="Loader" /><br />Loading... */}
          //     </div>
          // </div>
          <section className={"loader-wrapper"}>
              <div id="loader-wrapper">
                  <div id="loader" class="new-loader">
                      <div className="new-loader-anime"></div>
                      <img className="logo-white" src={LoaderLogo} className="loader-logo" alt="logo"/>
                  </div>
                  <div class="loader-section section-left"></div>
                  <div class="loader-section section-right"></div>
              </div>
          </section>
        ) :  data && data.jobs && data.jobs.length > 0 && 
        <> 
          <SEO title={data.jobs[0].Meta_Title} description={data.jobs[0].Meta_Description} />
      
          <Helmet/>

          
          <JobDetail role_data = {data.jobs.length > 0 && data.jobs[0]} openModalForm={openModalForm} location={props.location} />

          <Modal
            id="valuation_modal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
          >
            {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
            <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Send us Your CV</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CareerForm formName={`Send us Your CV`} formLabel={`Send us Your CV`} jobtitle={data.jobs[0].Title} />
            </Modal.Body>
          </Modal>
        
        </>

      }
      


      

    </Layout>
  )
}

export default CareersDetailsTemplate